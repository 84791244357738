import { render, staticRenderFns } from "./footer-desktop.vue?vue&type=template&id=14177981&scoped=true"
import script from "./footer-desktop.vue?vue&type=script&lang=js"
export * from "./footer-desktop.vue?vue&type=script&lang=js"
import style0 from "./footer-desktop.vue?vue&type=style&index=0&id=14177981&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14177981",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterNewsletterForm: require('/home/vsts/work/1/s/components/footer/footer-newsletter-form.vue').default})
