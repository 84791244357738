
import * as Sentry from '@sentry/vue'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import LoleContentNotifications from '~/components/menu/lole-content-notifications'
import HeaderDesktop from '~/components/header/header-desktop'
import HeaderMobile from '~/components/header/header-mobile'
import Backdrop from '~/components/common/backdrop'
import FooterDesktop from '~/components/footer/footer-desktop'
import FooterMobile from '~/components/footer/footer-mobile'
export default {
  components: {
    LoleContentNotifications,
    HeaderDesktop,
    HeaderMobile,
    Backdrop,
    FooterDesktop,
    FooterMobile,
  },
  head() {
    return {
      ...this.$nuxtI18nSeo(),
      script: [
        {
          src: `//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${this.$config.KLAVIYO.PUB_API_KEY}`,
          type: 'text/javascript',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      notification: (state) => state.notification,
      background: (state) => state.common.background,
      vxLocale: (state) => state.i18n.locale,
    }),
    ...mapGetters({
      vxCustomer: 'customer/getCustomer',
      vxIsOpenMobile: 'menu/getIsOpenMobile',
      vxFooter: 'common/getFooter',
      vxSignupNewsletter: 'common/getSignupNewsletter',
      vxIsOpenDesktop: 'menu/getIsOpenDesktop',
      vxIsSearchOpen: 'navigation/getSearchDesktopOpen',
      vxCurrency: 'currency/getCurrency',
    }),
  },
  watch: {
    $route() {
      document.body.classList.remove('no-scroll')
      this.dataLayerGTM()
      this.activeEventKlaviyo()
    },
    vxCustomer() {
      this.activeEventKlaviyo()
      // SENTRY SET USER
      Sentry.setUser({
        email: this.vxCustomer?.email,
        id: this.vxCustomer?.id,
      })
    },
    vxCurrency() {
      this.initializeRecentlyViewed()
    },
  },
  beforeMount() {
    if (this.$route.query?.currency) {
      const newQuery = Object.assign({}, this.$route.query)
      delete newQuery.currency
      this.$router.replace({ query: newQuery })
    }
  },
  async mounted() {
    this.initializeClient()
    this.dataLayerGTMFirstLoad()
    this.dataLayerGTM()

    await this.initializeRecentlyViewed()

    await this.initializeLocation()

    if (this.vxCustomer?.id && this.vxCustomer?.email) {
      // INITIALIZE STAMPED
      await this.initializeStamped()

      // ACTIVE ON SITE KLAVIYO
      this.activeEventKlaviyo()

      // SENTRY SET USER
      Sentry.setUser({
        email: this.vxCustomer.email,
        id: this.vxCustomer.id,
      })
    } else {
      // SENTRY CLEAR USER
      Sentry.setUser(null)
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.setWindowWidth)
  },
  methods: {
    ...mapMutations({
      vxSetScroll: 'setScroll',
    }),
    ...mapActions({
      vxCloseAllDesktop: 'navigation/closeAllDesktop',
      vxCloseAllMobile: 'navigation/closeAllMobile',
    }),
    setWindowWidth() {
      this.$store.commit('responsive/setWindowWidth')
    },
    initializeRecentlyViewed() {
      this.$store.dispatch('recentlyViewedProducts/init')
    },
    async initializeStamped() {
      if (this.vxCustomer) {
        try {
          await this.$store.dispatch('stamped/init', this.vxCustomer)
        } catch (e) {
          this.$sentryException(e, {
            source: 'default layout',
            level: 'warning',
            extra: 'Stamped init failed',
          })
        }
      }
    },
    async initializeLocation() {
      try {
        const countryFromCurrency =
          this.vxCurrency?.toLowerCase()?.substring(0, 2) || null

        const cachedGeoloc = await this.getCachedGeoloc()

        let countryFromGeo = null
        if (!cachedGeoloc) {
          const userIp = await this.$location.getIp()
          if (!userIp) return
          countryFromGeo = await this.$location.getLocation(userIp)
          this.saveCachedGeoloc(countryFromGeo)
        } else return

        if (!countryFromGeo || countryFromGeo === countryFromCurrency) return

        this.$store.commit('notification/setTopBarCountry', countryFromGeo)
        this.$store.commit('notification/setTopBar')
      } catch (error) {
        // this.$sentryException(error, {
        //   source: 'default layout',
        //   level: 'info',
        //   extra: `INITIALIZE LOCATION - failed to check users location and set the top banner: ${error}`,
        // })
      }
    },
    initializeClient() {
      window.addEventListener('scroll', this.handleScroll)
      window.addEventListener('resize', this.setWindowWidth)
      this.setWindowWidth()
    },
    handleScroll() {
      this.vxSetScroll(window.scrollY)
    },
    dataLayerGTMFirstLoad() {
      try {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          originalLocation:
            document.location.protocol +
            '//' +
            document.location.hostname +
            document.location.pathname +
            document.location.search,
        })
      } catch (e) {
        this.$sentryException(e, {
          source: 'GTM page product',
          level: 'warning',
          extra: 'GTM layout failed',
        })
      }
    },
    dataLayerGTM() {
      try {
        this.$gtm.push({
          uri:
            document.location.pathname +
            document.location.search +
            document.location.hash,
          language: this.$i18n.locale.includes('fr') ? 'FR' : 'EN',
          isMobile: !this.$store.getters['responsive/getIsWindowLarge'],
          currency: this.$i18n.locale.includes('ca')
            ? 'CAD'
            : this.$i18n.locale.includes('eu')
            ? 'EUR'
            : 'USD',
          loginStatus: this.vxCustomer ? 'logged' : 'disconnected',
          userId: this.vxCustomer
            ? this.vxCustomer.id.replace('gid://shopify/Customer/', '')
            : null,
          event: 'page_view',
        })
      } catch (e) {
        this.$sentryException(e, {
          source: 'GTM page product',
          level: 'warning',
          extra: 'GTM layout failed',
        })
      }
    },
    activeEventKlaviyo() {
      if (this.vxCustomer)
        this.$klaviyoEvents.ActiveOnSite(this.$route.path, this.vxCustomer)
    },
    getCachedGeoloc() {
      const cachedIp = localStorage.getItem('lole-geoloc')
      if (!cachedIp) return null
      const parsedIp = JSON.parse(cachedIp)
      const now = new Date()
      if (now.getTime() > parsedIp.expiration) {
        localStorage.removeItem('lole-geoloc')
        return null
      }
      return parsedIp.value
    },
    saveCachedGeoloc(value) {
      const now = new Date()
      const expiration = now.getTime() + 1000 * 60 * 60 * 24 * 7
      localStorage.setItem('lole-geoloc', JSON.stringify({ value, expiration }))
    },
  },
}
