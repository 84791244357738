export const strict = false

export const state = () => ({
  isLoading: false,
  scroll: {
    previous: 0,
    current: 0,
  },
})

export const getters = {
  getScroll(state) {
    return state.scroll
  },
}

export const mutations = {
  setScroll(state, position) {
    state.scroll.previous = state.scroll.current
    state.scroll.current = position
  },
}

export const actions = {
  async nuxtServerInit({ dispatch, commit }) {
    // LOCATION METAFIELDS
    try {
      const metafieldKeys = {
        breadcrumbs: 'breadcrumbs',
        model_groups: 'model_groups',
        care_instructions: 'care_instructions',
        feature_list_pdp: 'feature_list_pdp',
        colour_code_label: 'colour_code_label',
        benefits: 'benefits',
      }

      const { data } = await this.app.$axios.post(
        '/api/shopify/getLocationMetafields',
        {
          locationId: process.env.SHOPIFY_WEB_LOCATION_ID,
          appCacheTTL: 'FIVE_MINUTES',
        }
      )

      data?.location?.metafields?.nodes?.forEach(({ key, value }) => {
        if (metafieldKeys[key]) {
          commit('common/setLocationMetafield', {
            key: metafieldKeys[key],
            data: JSON.parse(value),
          })
        }
      })
    } catch (error) {
      console.log('ERROR fetch locationMetafields', error)
      // this.app.$sentryException(e, {
      //   source: 'store',
      //   level: 'fatal',
      //   extra: 'init application failed on locationMetafields',
      // })
    }

    // CURRENCY INIT
    try {
      await dispatch('currency/initialize')
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'store',
        level: 'fatal',
        extra: 'init application failed : dispatch(currency/initialize)',
      })
    }

    // STORYBLOK CONTENT INIT
    try {
      await dispatch('common/init')
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'store',
        level: 'fatal',
        extra: 'init application failed on storyblok init',
      })
      this.app.context.error({
        statusCode: 500,
        message: 'Store nuxtServerInit failed (storyblok init)',
      })
    }

    // CUSTOMER/INIT
    // - IF LOGGED
    //   - LOYALTY/INIT
    // - ELSE NOT LOGGED
    //   - CART/RETRIEVE CART
    try {
      await dispatch('customer/initialize').then(async () => {
        // CART
        try {
          const cartId = this.app.$cookies.get('lole-cart')
          if (cartId) await dispatch('cart/retrieveCart', { cartId })
          else await dispatch('cart/createCart', [])
        } catch (error) {
          this.app.$sentryException(error, {
            source: 'store',
            level: 'fatal',
            extra: 'init application failed on Cart/retrieveCart',
          })
        }
      })
    } catch (error) {
      this.app.$sentryException(error, {
        source: 'store',
        level: 'fatal',
        extra: 'init application failed : dispatch(customer/initialize)',
      })
    }
  },
}
