
import Swiper from 'swiper'
import { Autoplay, Scrollbar, Navigation } from 'swiper/modules'
export default {
  props: {
    slidesPerView: {
      type: Object,
      default: () => ({
        m: 2,
        t: 3,
        d: 5,
      }),
    },
    spaceBetween: {
      type: Object,
      default: () => ({
        m: 2,
        t: 3,
        d: 5,
      }),
    },
    loop: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: [Boolean, Number],
      default: false,
    },
    scrollBar: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    slider: null,
    hovered: false,
  }),
  computed: {
    slidesPerViewMobile() {
      return this.slidesPerView.m
    },
    spaceBetweenMobile() {
      return this.spaceBetween.m
    },
    slidesPerViewTablet() {
      return this.slidesPerView.t
    },
    spaceBetweenTablet() {
      return this.spaceBetween.t
    },
    slidesPerViewDesktop() {
      return this.slidesPerView.d
    },
    spaceBetweenDesktop() {
      return this.spaceBetween.d
    },
  },
  mounted() {
    this.slider = new Swiper(this.$refs.swiper, {
      modules: [Autoplay, Scrollbar, Navigation],
      speed: 500,
      loop: this.loop,
      autoplay: {
        delay: this.autoplay,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        enabled: this.autoplay,
      },
      slidesPerView: this.slidesPerViewMobile,
      spaceBetween: this.spaceBetweenMobile,
      breakpoints: {
        767: {
          slidesPerView: this.slidesPerViewTablet,
          spaceBetween: this.spaceBetweenTablet,
        },
        1024: {
          slidesPerView: this.slidesPerViewDesktop,
          spaceBetween: this.spaceBetweenDesktop,
        },
      },
      scrollbar: this.scrollBar
        ? {
            el: '.swiper-scrollbar',
            hide: true,
            draggable: true,
          }
        : false,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        dynamicBullets: false,
        clickable: true,
      },
    })
  },
  methods: {
    next() {
      this.slider.slideNext()
    },
    prev() {
      this.slider.slidePrev()
    },
  },
}
