
export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    itemActive: 1,
  }),
}
