class Location {
  constructor({ $axios, app, error }) {
    this.$axios = $axios
    this.app = app
    this.error = error
  }

  async getLocation(ip) {
    try {
      const apiKey = process.env.GEOIP_AZURE_API_KEY
      if (process.server) return
      const { data } = await this.$axios.get(
        `${window.location.origin}/geoipAzure/whereis/v1/json/${ip}`,
        { headers: { 'Fastah-Key': apiKey } }
      )
      if (data?.isEuropeanUnion) return 'eu'
      switch (data?.locationData?.countryCode) {
        case 'US':
          return 'us'
        case 'CA':
          return 'ca'
        default:
          return 'others'
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  async getIp() {
    try {
      const { data } = await this.$axios.get(
        `${window.location.origin}/ipify?format=json`
      )
      return data?.ip
    } catch (e) {
      throw new Error(e)
    }
  }
}

export default ({ $axios, app, error }, inject) => {
  const location = new Location({
    $axios,
    app,
    error,
  })
  inject('location', location)
}
