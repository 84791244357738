
import { mapActions, mapGetters } from 'vuex'
import SearchDesktop from '~/components/navigation/search-desktop'
import MenuDesktop from '~/components/menu/menu-desktop.vue'
export default {
  components: {
    SearchDesktop,
    MenuDesktop,
  },
  data: () => ({
    searchTerm: '',
    correctedQuery: '',
    itemLoad: null,
  }),
  computed: {
    ...mapGetters({
      vxContentMenu: 'menu/getContentMenu',
      vxContent: 'navigation/getContent',
      vxCartTotalQuantity: 'cart/getTotalQuantity',
      vxMenuLoaded: 'menu/getMenuDesktopLoaded',
      vxIsOpenDesktop: 'menu/getIsOpenDesktop',
      vxSearchIsOpen: 'navigation/getSearchDesktopOpen',
      vxCustomer: 'customer/getCustomer',
    }),
    menuLevel1Desktop() {
      if (this.vxContentMenu?.level1?.length > 0) {
        return this.vxContentMenu.level1.filter((item) => {
          return item.showOnDesktop
        })
      } else return []
    },
    nbProductCart() {
      return this.vxCartTotalQuantity
    },
  },
  methods: {
    ...mapActions({
      vxOpenMenu: 'menu/openMenuMobile',
      vxCloseMenu: 'menu/closeMenuDesktop',
      vxSetMenu: 'menu/openMenuDesktop',
      vxOpenSearchDesktop: 'navigation/toggleSearchDesktop',
      vxCloseAll: 'navigation/closeAllDesktop',
      vxRetrieveTrendingTerms: 'search/retrieveTrendingTerms',
    }),
    focusSearch() {
      this.vxCloseMenu()
      this.vxCloseAll()
      this.vxOpenSearchDesktop()
      this.vxRetrieveTrendingTerms()
    },
    goToSearchTerm() {
      this.$router.push(
        this.localePath(`/search?q=${this.searchTerm.toLowerCase()}`)
      )
      this.clearSearch()
    },
    clearSearch() {
      this.searchTerm = ''
      this.blurSearch()
      this.vxCloseMenu()
    },
    blurSearch() {
      this.vxCloseAll()
    },
    setMenu(menu) {
      if (this.vxSearchIsOpen) return
      setTimeout(() => {
        this.itemLoad = menu.name
        this.vxCloseAll()
        this.vxSetMenu(menu)
      }, 200)
    },
    goRoot() {
      this.blurSearch()
      this.clearSearch()
      this.vxCloseAll()
    },
    isItemActive(item) {
      return this.vxMenuLoaded === item.name
    },
    isWithoutChild(item) {
      return !(
        item.list_1.length > 0 ||
        item.list_2.length > 0 ||
        item.list_3.length > 0 ||
        item.list_4.length > 0
      )
    },
  },
}
