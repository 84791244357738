const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['dupSlashRedirect'] = require('../middleware/dupSlashRedirect.js')
middleware['dupSlashRedirect'] = middleware['dupSlashRedirect'].default || middleware['dupSlashRedirect']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['localeHeader'] = require('../middleware/localeHeader.js')
middleware['localeHeader'] = middleware['localeHeader'].default || middleware['localeHeader']

middleware['topBarNotification'] = require('../middleware/topBarNotification.js')
middleware['topBarNotification'] = middleware['topBarNotification'].default || middleware['topBarNotification']

export default middleware
