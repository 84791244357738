import Vue from 'vue'
// STATIC
import ComponentStaticBannerHtml from '~/components/storyblok/components-static/banners/component-static-banner-html'
import ComponentStaticBannerSvg from '~/components/storyblok/components-static/banners/component-static-banner-svg'
import ComponentStaticListArticles from '~/components/storyblok/components-static/component-static-list-articles'
import ComponentStaticListProducts from '~/components/storyblok/components-static/component-static-list-products'
import ComponentStaticTestimonials from '~/components/storyblok/components-static/component-static-testimonials'
import ComponentStaticMenuAnchor from '~/components/storyblok/components-static/component-static-menu-anchor'
// V2
// COMPONENTS
import ComponentStaticBanner from '~/components/storyblok/components-static/component-static-banner'
import ComponentStaticCarousel from '~/components/storyblok/components-static/component-static-carousel'
import ComponentStaticCarouselV3 from '~/components/storyblok/components-static/component-static-carousel-v3'
import ComponentStaticCarouselWithContent from '~/components/storyblok/components-static/component-static-carousel-with-content'
import ComponentStaticSeparator from '~/components/storyblok/components-static/component-static-separator'
import ComponentStaticSwitch from '~/components/storyblok/components-static/component-static-switch'
import ComponentStaticSwitch2 from '~/components/storyblok/components-static/component-static-switch-2'
import ComponentStaticCarouselCollections from '~/components/storyblok/components-static/component-static-carousel-collections'
import ComponentStaticTableInfo from '~/components/storyblok/components-static/component-static-table-info'
import ComponentStaticNewsletterForm from '~/components/storyblok/components-static/component-static-newsletter-form'
// STRUCTURE
import ComponentStaticColumn from '~/components/storyblok/components-static/structure/component-static-column'
import ComponentStaticRow from '~/components/storyblok/components-static/structure/component-static-row'
// BASE
import ComponentStaticBaseButton from '~/components/storyblok/components-static/base/component-static-base-button'
import ComponentStaticBaseImage from '~/components/storyblok/components-static/base/component-static-base-image'
import ComponentStaticBaseLink from '~/components/storyblok/components-static/base/component-static-base-link'
import ComponentStaticBaseParagraph from '~/components/storyblok/components-static/base/component-static-base-paragraph'
import ComponentStaticBaseText from '~/components/storyblok/components-static/base/component-static-base-text'
import ComponentStaticBaseTitle from '~/components/storyblok/components-static/base/component-static-base-title'
// HOMEPAGE
import ComponentHomepageBannerSlider from '~/components/storyblok/components-homepage/component-homepage-banner-slider/'
import ComponentHomepageBannerVideo from '~/components/storyblok/components-homepage/component-homepage-banner/component-homepage-banner-video'
import ComponentHomepageBannerSvg from '~/components/storyblok/components-homepage/component-homepage-banner/component-homepage-banner-svg'
import ComponentHomepageBannerHtml from '~/components/storyblok/components-homepage/component-homepage-banner/component-homepage-banner-html'
import ComponentHomepageBanner from '~/components/storyblok/components-homepage/component-homepage-banner/component-homepage-banner'
import ComponentHomepageBannerItemSvg from '~/components/storyblok/components-homepage/component-homepage-banner/content/component-homepage-banner-item-svg'
import ComponentHomepageBannerItemButton from '~/components/storyblok/components-homepage/component-homepage-banner/content/component-homepage-banner-item-button'
import ComponentHomepageBannerItemText from '~/components/storyblok/components-homepage/component-homepage-banner/content/component-homepage-banner-item-text'
import ComponentHomepageRow from '~/components/storyblok/components-homepage/component-homepage-row'
import ComponentHomepageVerticalShowcase from '~/components/storyblok/components-homepage/component-homepage-vertical-showcase'
import ComponentHomepageHorizontalShowcase from '~/components/storyblok/components-homepage/component-homepage-horizontal-showcase'
import ComponentHomepageCarouselProducts from '~/components/storyblok/components-homepage/component-homepage-carousel-products'
import ComponentHomepageCarouselCollections from '~/components/storyblok/components-homepage/component-homepage-carousel-collections'
import ComponentHomepageSeparator from '~/components/storyblok/components-homepage/component-homepage-separator'
import ComponentHomepageBannerV2 from '~/components/storyblok/components-homepage/component-homepage-banner-v2/component-homepage-banner-v2'
import ComponentHomepageRowV2 from '~/components/storyblok/components-homepage/component-homepage-banner-v2/structure/component-homepage-row-v2'
import ComponentHomepageColumn from '~/components/storyblok/components-homepage/component-homepage-banner-v2/structure/component-homepage-column'
import ComponentHomepageBaseButton from '~/components/storyblok/components-homepage/component-homepage-banner-v2/base/component-homepage-base-button'
import ComponentHomepageBaseTitle from '~/components/storyblok/components-homepage/component-homepage-banner-v2/base/component-homepage-base-title'
import ComponentHomepageBaseText from '~/components/storyblok/components-homepage/component-homepage-banner-v2/base/component-homepage-base-text'

export default () => {
  // STATIC
  Vue.component('ComponentStaticBannerHtml', ComponentStaticBannerHtml)
  Vue.component('ComponentStaticBannerSvg', ComponentStaticBannerSvg)
  Vue.component('ComponentStaticListArticles', ComponentStaticListArticles)
  Vue.component('ComponentStaticListProducts', ComponentStaticListProducts)
  Vue.component('ComponentStaticTestimonials', ComponentStaticTestimonials)
  Vue.component('ComponentStaticMenuAnchor', ComponentStaticMenuAnchor)
  Vue.component('ComponentStaticBanner', ComponentStaticBanner)
  Vue.component('ComponentStaticCarousel', ComponentStaticCarousel)
  Vue.component('ComponentStaticCarouselV3', ComponentStaticCarouselV3)
  Vue.component(
    'ComponentStaticCarouselWithContent',
    ComponentStaticCarouselWithContent
  )
  Vue.component('ComponentStaticSeparator', ComponentStaticSeparator)
  Vue.component('ComponentStaticSwitch', ComponentStaticSwitch)
  Vue.component('ComponentStaticSwitch2', ComponentStaticSwitch2)
  Vue.component(
    'ComponentStaticCarouselCollections',
    ComponentStaticCarouselCollections
  )
  Vue.component('ComponentStaticTableInfo', ComponentStaticTableInfo)
  Vue.component('ComponentStaticNewsletterForm', ComponentStaticNewsletterForm)
  Vue.component('ComponentStaticColumn', ComponentStaticColumn)
  Vue.component('ComponentStaticRow', ComponentStaticRow)
  Vue.component('ComponentStaticBaseButton', ComponentStaticBaseButton)
  Vue.component('ComponentStaticBaseImage', ComponentStaticBaseImage)
  Vue.component('ComponentStaticBaseLink', ComponentStaticBaseLink)
  Vue.component('ComponentStaticBaseParagraph', ComponentStaticBaseParagraph)
  Vue.component('ComponentStaticBaseText', ComponentStaticBaseText)
  Vue.component('ComponentStaticBaseTitle', ComponentStaticBaseTitle)
  // HOMEPAGE
  Vue.component('ComponentHomepageBannerSlider', ComponentHomepageBannerSlider)
  Vue.component('ComponentHomepageBannerVideo', ComponentHomepageBannerVideo)
  Vue.component('ComponentHomepageBannerSvg', ComponentHomepageBannerSvg)
  Vue.component('ComponentHomepageBannerHtml', ComponentHomepageBannerHtml)
  Vue.component('ComponentHomepageBanner', ComponentHomepageBanner)
  Vue.component(
    'ComponentHomepageBannerItemSvg',
    ComponentHomepageBannerItemSvg
  )
  Vue.component(
    'ComponentHomepageBannerItemButton',
    ComponentHomepageBannerItemButton
  )
  Vue.component(
    'ComponentHomepageBannerItemText',
    ComponentHomepageBannerItemText
  )
  Vue.component('ComponentHomepageRow', ComponentHomepageRow)
  Vue.component(
    'ComponentHomepageVerticalShowcase',
    ComponentHomepageVerticalShowcase
  )
  Vue.component(
    'ComponentHomepageHorizontalShowcase',
    ComponentHomepageHorizontalShowcase
  )
  Vue.component(
    'ComponentHomepageCarouselProducts',
    ComponentHomepageCarouselProducts
  )
  Vue.component(
    'ComponentHomepageCarouselCollections',
    ComponentHomepageCarouselCollections
  )
  Vue.component('ComponentHomepageSeparator', ComponentHomepageSeparator)
  Vue.component('ComponentHomepageBannerV2', ComponentHomepageBannerV2)
  Vue.component('ComponentHomepageRowV2', ComponentHomepageRowV2)
  Vue.component('ComponentHomepageColumn', ComponentHomepageColumn)
  Vue.component('ComponentHomepageBaseButton', ComponentHomepageBaseButton)
  Vue.component('ComponentHomepageBaseTitle', ComponentHomepageBaseTitle)
  Vue.component('ComponentHomepageBaseText', ComponentHomepageBaseText)

  // IGNORED COMPONENTS
  Vue.config.ignoredElements = ['afterpay-placement']
}
