export const state = () => ({
  // CAD, USD, EUR
  currency: null,
})

export const getters = {
  getCurrency: (state) => state.currency,
}

export const mutations = {
  setCurrency(state, currency) {
    state.currency = currency
  },
}

export const actions = {
  async initialize({ commit, getters }) {
    try {
      const locale = this.app.i18n.locale
      const routeQuery = this.app.context.route.query || {}
      const acceptedCurrencies = { CAD: true, USD: true, EUR: true }

      // IF CURRENCY IS SET IN URL PARAMS
      const currencyFromUrl = acceptedCurrencies?.[
        routeQuery?.currency?.toUpperCase()
      ]
        ? routeQuery.currency
        : null

      // IF CURRENCY IS SET IN COOKIES
      const currencyFromCookies =
        this.app.$cookies.get('lole-currency') || 'CAD'

      const currency = (currencyFromUrl || currencyFromCookies).toUpperCase()

      // DEFAULT TO ENGLISH IF CURRENCY IS USD
      if (currency === 'USD' && locale === 'fr') {
        this.app.$cookies.remove(`lole_i18n`)
        this.app.$cookies.set(`lole_i18n`, 'en')
        this.app.i18n.setLocale('en')
      }
      commit('setCurrency', currency)

      await this.app.$cookies.set('lole-currency', currency, {
        maxAge: 60 * 60 * 24 * 30,
        path: '/',
      })
    } catch (e) {
      this.app.$cookies.remove('lole-currency')
    }
  },
  async switchCurrency({ commit }, currency) {
    await this.app.$cookies.set('lole-currency', currency, {
      maxAge: 60 * 60 * 24 * 30,
      path: '/',
    })
    commit('setCurrency', currency)
  },
}
