import { parseCommon } from './helpers'

export const state = () => ({
  footer: null,
  errorStory: null,
  switchLocator: null,
  signupNewsletter: null,
  searchStory: null,
  popupLogin: null,
  recentlyViewed: null,
  snackbarsStory: null,
  topBarSwitch: null,
  subHeader: null,
  productsFlaps: null,
  errorsForm: null,
  confirmationStory: null,
  locationMetaTimestamp: null,
  breadcrumbs: null,
  model_groups: null,
  care_instructions: null,
  feature_list_pdp: null,
  colour_code_label: null,
  benefits: null,
})

export const getters = {
  getFooter(state) {
    return state.footer
  },
  getErrorStory(state) {
    return state.errorStory
  },
  getSwitchLocator(state) {
    return state.switchLocator
  },
  getSignupNewsletter(state) {
    return state.signupNewsletter
  },
  getSearchStory(state) {
    return state.searchStory
  },
  getPopupLogin(state) {
    return state.popupLogin
  },
  getRecentlyViewed(state) {
    return state.recentlyViewed
  },
  getSnackbarsStory(state) {
    return state.snackbarsStory
  },
  getTopBarSwitch(state) {
    return state.topBarSwitch
  },
  getSubHeader(state) {
    return state.subHeader
  },
  getProductsFlaps(state) {
    return state.productsFlaps
  },
  getErrorsForm(state) {
    return state.errorsForm
  },
  getConfirmationStory(state) {
    return state.confirmationStory
  },
  getLocationMetaTimestamp(state) {
    return state.locationMetaTimestamp
  },
  getBreadcrumbs(state) {
    return state.breadcrumbs
  },
  getModelGroups(state) {
    return state.model_groups
  },
  getCareInstructions(state) {
    return state.care_instructions
  },
  getFeatureListPdp(state) {
    return state.feature_list_pdp
  },
  getColourCodeLabel(state) {
    return state.colour_code_label
  },
  getBenefits(state) {
    return state.benefits
  },
  getSizeGuide(state) {
    return state.sizeGuide
  },
}

export const mutations = {
  setFooter(state, value) {
    state.footer = value
  },
  setErrorStory(state, value) {
    state.errorStory = value
  },
  setSwitchLocator(state, value) {
    state.switchLocator = value
  },
  setSignupNewsletter(state, value) {
    state.signupNewsletter = value
  },
  setSearchStory(state, value) {
    state.searchStory = value
  },
  setPopupLogin(state, value) {
    state.popupLogin = value
  },
  setRecentlyViewed(state, value) {
    state.recentlyViewed = value
  },
  setSnackbarsStory(state, value) {
    state.snackbarsStory = value
  },
  setTopBarSwitch(state, value) {
    state.topBarSwitch = value
  },
  setSubHeader(state, value) {
    state.subHeader = value
  },
  setProductsFlaps(state, value) {
    state.productsFlaps = value
  },
  setErrorsForm(state, value) {
    state.errorsForm = value
  },
  setConfirmationStory(state, value) {
    state.confirmationStory = value
  },
  setLocationMetaTimestamp(state, value) {
    state.locationMetaTimestamp = value
  },
  setLocationMetafield(state, payload) {
    state[payload.key] = payload.data
  },
  setSizeGuide(state, value) {
    state.sizeGuide = value
  },
}

export const actions = {
  async init({ commit, getters, rootGetters }) {
    // STORYBLOK - SET VERSION
    try {
      await this.app.$storyblok.setVersion()
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'store',
        level: 'fatal',
        extra: 'init application failed on storyblok set version',
      })
      this.app.context.error({
        statusCode: 500,
        message: 'Store common/init failed',
      })
    }
    const lang = this.app?.i18n?.locale.split('-')?.[0]
    // STORYBLOK CALL
    const { stories } = await this.$storyblok.getStories(
      `by_slugs=${lang}/common/*,${lang}/navigation/*,${lang}/pages/collection-page/*`
    )
    const {
      footer,
      errorStory,
      switchLocator,
      signupNewsletter,
      searchStory,
      popupLogin,
      recentlyViewed,
      snackbarsStory,
      topBarSwitch,
      subHeader,
      productsFlaps,
      errorsForm,
      confirmationStory,
      navigationStory,
      menuStory,
      search2Story,
      bannersStory,
      categoryStory,
      carouselColorsFilter,
      sizeGuide,
    } = parseCommon(stories)
    commit('setFooter', footer)
    commit('setErrorStory', errorStory)
    commit('setSwitchLocator', switchLocator)
    commit('setSignupNewsletter', signupNewsletter)
    commit('setSearchStory', searchStory)
    commit('setPopupLogin', popupLogin)
    commit('setRecentlyViewed', recentlyViewed)
    commit('setSnackbarsStory', snackbarsStory)
    commit('setTopBarSwitch', topBarSwitch)
    commit('setSubHeader', subHeader)
    commit('setProductsFlaps', productsFlaps)
    commit('setErrorsForm', errorsForm)
    commit('setConfirmationStory', confirmationStory)
    commit('setSizeGuide', sizeGuide)
    // NAVIGATION
    commit('navigation/setContent', navigationStory, { root: true })
    commit('menu/setContentMenu', menuStory, { root: true })
    // SEARCHSPRING
    commit('search/setStory', search2Story, { root: true })

    // SEARCH QUERY / REFRESH SEARCH PAGE TITLE
    commit('search/setSearchPageTitle', null, { root: true })

    // BANNERS
    commit('search/setBanners', bannersStory, { root: true })
    commit('search/setCategoriesStory', categoryStory, { root: true })
    commit('search/setCarouselColorsFilter', carouselColorsFilter, {
      root: true,
    })
    // NOTIFICATION
    commit('notification/setSnackbarsStory', getters.getSnackbarsStory, {
      root: true,
    })
    commit('notification/setTopBarSwitch', getters.getTopBarSwitch, {
      root: true,
    })
    commit('notification/setSubHeader', getters.getSubHeader, {
      root: true,
    })
  },

  setLocationMetaTimestamp({ commit }, value) {
    commit('setLocationMetaTimestamp', value)
  },

  setBreadcrumbs({ commit }, value) {
    commit('setBreadcrumbs', value)
  },

  setModelGroups({ commit }, value) {
    commit('setModelGroups', value)
  },
}
