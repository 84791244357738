import btoa from 'btoa'
import productSearchspringModel from '~/models/searchspring/product'
import sizesSorter from '~/helpers/sizesSorter'

export default class ParseProducts {
  constructor({ store, locale }) {
    this.store = store
    this.locale = locale
  }

  parse(data, currency) {
    try {
      const model_groups = this.store.getters['common/getModelGroups']
      let groupByHandle = {}

      if (process.env.DEPLOYMENT_ENV === 'staging') {
        groupByHandle = Object.assign(
          model_groups?.groupByHandle,
          model_groups?.groupByHandle1
        )
      } else {
        groupByHandle = model_groups?.groupByHandle
      }

      const resultProducts = []

      data.results.forEach((item) => {
        const product = {
          ...productSearchspringModel,
        }

        product.modelGroup = groupByHandle?.[item.handle]
        product.id = `gid://shopify/Product/${item.uid.split('-')[0]}`
        product.handle = `${item?.handle}-${item?.variant_color?.[0]}`
        product.title = item?.name

        // SIZES
        let sizes = null
        if (item?.ss_sizes)
          sizes = JSON.parse(item?.ss_sizes.replace(/&quot;/g, '"'))
        if (sizes)
          product.sizes = sizes
            .map((size) => {
              return {
                name: size.size,
                availability: size.available,
                id: btoa('gid://shopify/ProductVariant/' + size.id),
              }
            })
            .sort((a, b) => sizesSorter[a.name] - sizesSorter[b.name])

        // MEDIAS
        product.medias = item.ss_images
          .reduce((acc, image) => {
            const imageParts = image.split('/').pop().split('_')
            const extractedColor = imageParts[1]
            const imagePosition = parseInt(imageParts[imageParts.length - 2])
            const variantColor = item?.variant_color?.[0]
            if (variantColor === extractedColor)
              acc.push({
                src: image.replace('_320x', ''),
                type: 'image',
                position: imagePosition,
              })
            return acc
          }, [])
          .sort((a, b) => a.position - b.position)

        if (product.medias.length === 0 && item?.imageUrl) {
          product.medias.push({
            src: item.imageUrl.replace('_320x', '') || '',
            type: 'image',
            position: 1,
          })
        }

        product.normalized_color = item?.ss_pim_normalized_color?.[0]
        product.sample_color = item?.ss_pim_color_sample?.[0]
        product.internal_color_code = item?.variant_color?.[0]
        product.internal_type = item?.sku?.split('-')?.[0]

        // CURRENCY
        product.currency = currency

        // PRICES
        if (currency)
          switch (currency) {
            case 'CAD':
              product.price = item?.ss_price_ca
              product.strikeout_price = item?.ss_strikeout_price_ca
              break
            case 'USD':
              product.price = item?.ss_price
              product.strikeout_price = item?.ss_retail
              break
            case 'EUR':
              product.price = item?.ss_price_eu
              product.strikeout_price = item?.ss_strikeout_price_eu
              break
            default:
              product.price = null
              product.strikeout_price = null
          }

        // PRICE BYPASS FOR STAGING:
        if (process.env.DEPLOYMENT_ENV === 'staging') {
          product.price = item?.ss_price
          product.strikeout_price = item?.ss_price
        }

        // PRODUCT CATEGORIZATION
        const PCResult = []
        const PCGender = item?.ss_pim_gender?.[0]
        const PCNavCategory = item?.ss_pim_nav_category?.[0]
        const PCNavDivision = item?.ss_pim_nav_division?.[0]?.replaceAll(
          '/',
          ','
        )
        if (PCGender) PCResult.push(PCGender)
        if (PCNavCategory) PCResult.push(PCNavCategory)
        if (PCNavDivision) PCResult.push(PCNavDivision)
        product.productCategorization = PCResult.join(' / ').toLocaleLowerCase()

        product.isFinalSale = item?.ss_pim_final_sale?.[0] === 'true'
        product.tags = item?.tags
        product.flaps = item?.ss_pim_flaps_variant

        // ------------
        // TAGS PARSED
        // ------
        product.tagsParsed = product.tags.reduce((acc, tag) => {
          const splitTag = tag.split(':')
          if (splitTag.length === 2) {
            acc[splitTag[0]] = splitTag[1]
          }
          return acc
        }, {})

        product.season = product.tagsParsed?.en_facet_season
        product.sku = item?.sku

        product.intellisuggestData = item?.intellisuggestData
        product.intellisuggestSignature = item?.intellisuggestSignature

        // PUSH RESULT
        if (product?.price !== '0' && product.medias.length > 0)
          resultProducts.push(product)

        // TEST PRODUCT DEV ONLY
        // const testResults = this.#parseTest(product)
        // console.log('DEBUG parseTest', testResults)
      })

      return { resultProducts }
    } catch (error) {
      console.error('ERROR parseProducts', error)
    }
  }

  #parseTest = (product) => {
    const results = []

    if (!product.modelGroup) results.push({ message: 'modelGroup is missing' })
    if (!product.id) results.push({ message: 'id is missing' })
    if (!product.handle) results.push({ message: 'handle is missing' })
    if (!product.title) results.push({ message: 'title is missing' })
    if (!product.sizes) results.push({ message: 'sizes is missing' })
    if (!product.medias || product.medias.length === 0)
      results.push({ message: 'medias is missing' })
    if (!product.normalized_color)
      results.push({ message: 'normalized_color is missing' })
    if (!product.sample_color)
      results.push({ message: 'sample_color is missing' })
    if (!product.internal_color_code)
      results.push({ message: 'internal_color_code is missing' })
    if (!product.internal_type)
      results.push({ message: 'internal_type is missing' })
    if (!product.price) results.push({ message: 'price is missing' })
    if (!product.strikeout_price)
      results.push({ message: 'strikeout_price is missing' })
    if (!product.productCategorization)
      results.push({ message: 'productCategorization is missing' })
    if (!product.isFinalSale)
      results.push({ message: 'isFinalSale is missing' })
    if (!product.tags) results.push({ message: 'tags is missing' })
    if (!product.flaps) results.push({ message: 'flaps is missing' })
    if (!product.tagsParsed) results.push({ message: 'tagsParsed is missing' })
    if (!product.season) results.push({ message: 'season is missing' })
    if (!product.categories) results.push({ message: 'categories is missing' })
    if (!product.sku) results.push({ message: 'sku is missing' })

    return results
  }
}
