export const state = () => ({
  profile: null,
  transactions: [],
})

export const getters = {
  getProfile(state) {
    return state.profile
  },
  getTransactions(state) {
    return state.transactions
  },
  getPointsFromReviews(state) {
    const transactions = state?.transactions
    const campaignStartDate = new Date('2024-08-21T22:59:00').getTime()
    const campaignEndDate = new Date('2024-08-31T22:59:00').getTime()

    const reviewTransactions = transactions.reduce((acc, transaction) => {
      const { created_at, resource, adjustment, points_approved } = transaction
      const adjustmentNote = adjustment?.note
      const createdAt = new Date(created_at).getTime()
      const transactionInCampaignRange =
        createdAt > campaignStartDate && createdAt < campaignEndDate

      if (transactionInCampaignRange && adjustmentNote === 'product reviewed') {
        acc.push({
          created_at,
          resource,
          adjustment,
          points_approved,
        })
      }
      return acc
    }, [])

    const reviewPoints = reviewTransactions.reduce((acc, transaction) => {
      return acc + transaction.points_approved
    }, 0)

    return reviewPoints
  },
  getLoyaltyTier(state) {
    return state?.profile?.loyalty_tier_membership?.loyalty_tier || null
  },
}

export const mutations = {
  setProfile(state, profile) {
    state.profile = profile
  },
  setTransactions(state, transactions) {
    state.transactions = transactions
  },
  addToPoints(state, points) {
    state.profile.points_approved += parseInt(points)
  },
}

export const actions = {
  async init({ commit, getters, dispatch }, customer) {
    try {
      const profile = getters.getProfile
      if (!profile?.email && customer?.email) {
        await this.$axios
          .post('/api/loyalty/customer', {
            email: customer.email,
          })
          .catch((error) => {
            return error
          })
          .then((result) => {
            if (result.data) {
              if (result.data.customers.length && result.data.customers[0]) {
                commit('setProfile', result.data.customers[0])
              }
            } else {
              throw new Error('No result data for loyalty customer')
            }
          })
      }

      if (profile?.merchant_id)
        dispatch('fetchTransactions', profile.merchant_id)
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'loyaltylion',
        level: 'warning',
        extra: 'Store - callLoyaltyLion - init(): ' + e,
      })
      throw new Error(e)
    }
  },
  async fetchTransactions({ commit }, merchantId) {
    try {
      await this.$axios
        .post('/api/loyalty/customer/transactions', {
          merchantId,
        })
        .catch((error) => {
          return error
        })
        .then((response) => {
          const { data } = response
          if (data?.transactions) {
            commit('setTransactions', data.transactions)
          }
        })
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'loyaltylion',
        level: 'warning',
        extra: 'Store - callLoyaltyLion - fetchTransactions()',
      })
      throw new Error(e)
    }
  },
  async updateCustomer({ commit }, payload) {
    try {
      await this.$axios
        .post('/api/loyalty/customer/update', {
          payload,
        })
        .catch((error) => {
          return error
        })
        .then((result) => {
          if (result.data.customer) commit('setProfile', result.data.customer)
        })
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'loyaltylion',
        level: 'warning',
        extra: 'Store - callLoyaltyLion - updateCustomer()',
      })
      throw new Error(e)
    }
  },
  async awardPoints({ commit }, payload) {
    try {
      await this.$axios
        .post('/api/loyalty/customer/awardPoints', {
          payload,
        })
        .catch((error) => {
          return error
        })
        .then((result) => {
          commit('addToPoints', payload.points)
        })
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'loyaltylion',
        level: 'warning',
        extra: 'Store - callLoyaltyLion - awardPoints()',
      })
      throw new Error(e)
    }
  },
  clear({ commit }) {
    try {
      commit('setProfile', null)
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'storyblok',
        level: 'warning',
        extra: 'Store - clear()',
      })
      throw new Error(e)
    }
  },
}
