import { render, staticRenderFns } from "./footer-mobile.vue?vue&type=template&id=0a8f4759"
import script from "./footer-mobile.vue?vue&type=script&lang=js"
export * from "./footer-mobile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterNewsletterForm: require('/home/vsts/work/1/s/components/footer/footer-newsletter-form.vue').default,FooterMobileList: require('/home/vsts/work/1/s/components/footer/footer-mobile-list.vue').default})
